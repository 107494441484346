<template>
  <div class="main-area">
    <Navbar />
    <div class="body p-3">
      <div class="article pt-16">
        <h1>Which chart would you like to use?</h1>
        <div class="flex my-3">
          <a
            v-for="hospital in hospitals()"
            :key="hospital.id"
            :href="`/fill/${hospital.id}`"
            class="rounded shadow text-center"
          >
            <img :src="`/data/${hospital.id}/logo.png`">
            <div class="text-black font-bold">{{ hospital.name }}</div>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>

import Navbar from './Navbar.vue'
import Footer from './Footer.vue'

export default {
  components: {
    Navbar,
    Footer,
  },

  methods: {
    hospitals() {
      return [{
        name: 'St. Vincent\'s Greenslopes',
        id: 'st-vincents',
      }]
    },
  },
}
</script>

<style lang="scss" scoped>

.main-area {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.article {
  width: 80ex;
  margin: auto;

  a {
    width: 22ex;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.2);
    div {
      padding: 0.6rem 1rem;
      position: absolute;
      bottom: 0;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(3px);
    }
  }
}

</style>
